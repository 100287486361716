.search-homepage-view {
  .search-homepage-header {
    max-width: 950px;
    margin: 0 auto;
  }

  .search-block-filters {
    &.no-results {
      margin-bottom: 130px;
    }
    &.has-results {
      margin-bottom: 30px;
    }

    .search-container .filter-wrapper {
      &.text-filter {
        flex-grow: 2;
      }
    }
  }

  .react-select__single-value {
    font-weight: 400;
  }

  .react-select__option.react-select__option--is-selected {
    background-color: lighten($primary, 10%);
  }

  .specific-searches {
    margin-right: auto;

    .fa-external-link-alt {
      margin-left: 0.5em;
    }
  }

  .result-item {
    border-bottom: 1px solid #bbb;

    .item-description {
      em {
        font-style: normal;
        background-color: transparentize($tertiary, 0.8);
      }
    }
  }

  .link-breadcrumb {
    color: #666;
    text-decoration: none;

    &:hover {
      color: #444;
      text-decoration: underline;
    }
  }

  .ellipsis {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media (min-width: map-get($grid-breakpoints, 'lg')) {
    .description {
      white-space: pre-wrap;
    }
  }

  @media (max-width: map-get($grid-breakpoints, 'lg') - 1px) {
    .search-block-filters {
      .search-container .filter-wrapper {
        &.select-filter {
          border: 1px solid transparentize(white, $amount: 0.5);
          border-radius: 4px;
        }
      }
    }
  }
}
