//site
$primary-h: 205.16 !default;
$primary-s: 91.18 !default;
$primary-b: 40 !default;
// $primary: #004766;

//$secondary;
// $tertiary: #ed1c25;
//$tertiary-text: white;

// GDRP-BANNER RELATED
// $gdpr-accept-all: #005700 !default;
// $gdpr-toggle-checked: #005700 !default;
// $gdpr-toggle: #b22515 !default;
// $gdpr-toggle-border: #000 !default;
// $gdpr-focus-color: #ff9800 !default;
// $gdpr-link-color: #004285 !default;
// $gdpr-focus-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
//   0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem $gdpr-focus-color !default;

$header-slim-bg-color: #1074bb;

//$headings-color: $primary;
//$link-color: hsl(210deg, 100%, 40%);
